<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createProject">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesProyecto') }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="project-name"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Referencia')"
              rules="required"
            >
              <b-form-group
                :label="$t('Referencia')"
                label-for="project-reference"
              >
                <b-form-input
                  v-model="reference"
                  name="reference"
                  :placeholder="$t('Referencia')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Localizacion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Localizacion')"
                label-for="project-reference"
              >
                <b-form-input
                  v-model="location"
                  name="reference"
                  :placeholder="$t('Localizacion')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Estado')"
              rules="required"
            >
              <b-form-group
                :label="$t('Estado')"
                label-for="project-status"
              >
                <v-select
                  label="alias"
                  :filterable="false"
                  :searchable="false"
                  :options="selectStatus"
                  :placeholder="$t('Estado')"
                  v-model="status"
                >
                  <template slot="option" slot-scope="option">
                    {{ $t('status.projects.' + option.alias) }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ $t('status.projects.' + option.alias) }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              v-for="client in selectDepartments"
              :key="`client-${client.id}`"
              :label="$t('Departamentos')"
              label-for="project-departments"
            >
              <v-select
                label="name"
                :filterable="false"
                :searchable="false"
                :options="client.data"
                multiple
                v-model="departments"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Usuarios')"
              label-for="project-users"
            >
              <SelectUsers v-model="users" />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="project-description"
              >
                <quill-editor
                  v-model="description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('FechaInicio')"
              rules="required"
            >
              <b-form-group
                :label="$t('FechaInicio')"
                label-for="project-fechaini"
              >
                <flat-pickr
                  v-model="date_ini"
                  name="fecha_ini"
                  class="form-control"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('FechaFin')"
              label-for="project-fechafin"
            >
              <flat-pickr
                v-model="date_fin"
                name="fecha_ini"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="$t('Documentos')"
              label-for="project-documents"
            >
              <ImageDropzone
                ref="documents"
                :files="files"
                maxFiles="10"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import SelectUsers from '@/components/selectUsers/SelectUsers.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    SelectUsers
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      reference: '',
      location: '',
      description: '',
      date_ini: '',
      date_fin: '',
      departments: [],
      users: [],
      files: [],
      status: '',
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      localeDatePicker: 'languages/getLocaleDatePicker',
      selectStatus: 'projects/getSelectStatus',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      selectDepartments: 'departments/getSelectDepartments',
      selectUsers: 'users/getSelectUsers',
    }),
  },
  methods: {
    ...mapActions({
      create: 'projects/create',
      getSelectStatus: 'projects/selectStatus',
      getSelectDepartments: 'departments/selectDepartments',
      getSelectUsers: 'users/selectUsers',
    }),
    handleSubmit() {
      this.$refs.createProject.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ project: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')
      data.append('date_ini', FlatPickrToTimestamp(this.date_ini))
      if (this.date_fin) {
        data.append('date_fin', FlatPickrToTimestamp(this.date_fin))
      } else {
        data.append('date_fin', null)
      }
      if (this.departments.length > 0) {
        this.departments.forEach(element => {
          data.append('departments[]', element.id)
        })
      } else {
        data.append('departments', '')
      }
      if (this.users.length > 0) {
        this.users.forEach(element => {
          data.append('users[]', element.id)
        })
      } else {
        data.append('users', '')
      }

      data.append('name', this.name)
      data.append('status_project_id', this.status.id)
      data.append('location', this.location)
      data.append('client_id', this.currentClient.id)
      data.append('description', this.description)
      data.append('reference', this.reference)

      return data
    },
  },
  async created() {
    await this.getSelectStatus()
    await this.getSelectDepartments({ clients: [this.currentClient] })
    await this.getSelectUsers({ company: this.currentCompany.id, client: this.currentClient.id })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
