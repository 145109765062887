<template>
  <div>
    <v-select
      :value="value"
      :options="selectUsers"
      :filterable="false"
      :multiple="multiple"
      label="name"
      @open="onOpen"
      @close="onClose"
      @search="search = $event"
      @input="$emit('input', $event)"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          {{ $i18n.t("LoadingMoreOptions") }}
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import usersApi from "@/api/users-api";

export default {
  components: {
    vSelect,
  },
  props: ['value', 'multiple'],
  data() {
    return {
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: '',
      selectUsers: [],
      selected: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
    }),
    hasNextPage() {
      let output = true
      if (this.lastPage === this.currentPage) {
        output = false
      }
      if (this.selectUsers.length === 0) {
        output = false
      }

      return output
    },
  },
  watch: {
    async search() {
      this.currentPage = 1
      this.selectUsers = []
      await this.chargeData(true)
    },
  },
  async mounted() {
    await this.chargeData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    ...mapActions({
      getselectUsers: 'users/selectUsers',
    }),
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async chargeData(ifSearch = false) {
      const cliente = this.currentClient ? this.currentClient.id : ''
      if(this.currentCompany){
        await usersApi.list(this.currentCompany.id, cliente, '', this.currentPage, this.pageLength, this.search)
            .then(
                response => {
                  this.selectUsers = (ifSearch) ? response.data.data : this.selectUsers.concat(response.data.data)
                  this.lastPage = response.data.meta.last_page
                },
                () => {
                  console.log('ERROR')
                },
            )
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.currentPage += 1
        await this.chargeData()
        ul.scrollTop = scrollTop
      }
    },

  },
}
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
